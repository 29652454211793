<script setup>
import { defineExpose, ref, getCurrentInstance } from 'vue';
import cTable from '@/components/CustomTable'
import { ElMessageBox, ElMessage } from 'element-plus';

const { proxy } = getCurrentInstance() // 当前实例

const merchantData = ref(null)
const table = ref(null)
const tableDataOrigin = ref()
const isLoading = ref(false)

// 查询收费配置
const queryServiceCharge = (data) => {
    proxy.$api.merchants.serviceCharge(data).then((r) => {
        tableDataOrigin.value = r.data.data.config
        // 设置表单项值，确保查询到的配置值正确填充
        serviceChargeConfig.value = { ...r.data.data.config }
        table.value.setTableData([r.data.data.config])
    })
}

const isShowConfig = ref(false)

// 打开服务费配置
const openConfig = (data) => {
    serviceChargeConfig.value = { ...data }
    isShowConfig.value = true
    // 如果是按流量计费，发起查询
    if (serviceChargeConfig.value.api_billing_type === 2) {
        isLoading.value = true
        queryAllChannelRate()
    }
}

const serviceChargeConfig = ref({
    api_billing_type: 0,
    api_expire_time: '',
    api_monthly_fee: '',
    api_balance: '',
    api_min_recharge_amount: '',
    api_fee_rate: ''
})

// 监听计费方式变化
const handleBillingTypeChange = (val) => {
    console.log(val)
    if (val == 2) {
        isLoading.value = true
        queryAllChannelRate()
    }
}

//查询所有通道费率
const channelRate = ref([])
const queryAllChannelRate = () => {

    proxy.$api.merchants.allChannelRate(merchantData.value.id).then((r) => {
        channelRate.value = r.data.data.list

        channelRate.value.forEach((channel) => {
            channel.originalApiFeeRate = channel.api_fee_rate;
        });
        console.log(channelRate.value, "channelRate.value");
        isLoading.value = false
    })
}

const handleFeeRateBlur = (newValue, channel) => {
    const oldValue = channel.originalApiFeeRate;

    if (newValue !== oldValue) {
        ElMessageBox.confirm(
            `您修改了${channel.payment_channel.name}通道费率，是否保存更改？`,
            '确认修改',
            {
                confirmButtonText: '保存',
                cancelButtonText: '取消',
                type: 'warning',
            }
        ).then(() => {
            // 调用修改通道费率的接口
            updateChannelRate(channel, newValue);
            // 更新当前行的费率和原始费率
            channel.api_fee_rate = newValue;
            channel.originalApiFeeRate = newValue; // 更新原始费率为新的费率
        }).catch(() => {
            // 恢复旧值
            channel.api_fee_rate = oldValue;
        });
    }
};

// 修改特定通道费率
const updateChannelRate = (channel, newFeeRate) => {
    const data = {
        api_fee_rate: newFeeRate,
        payment_channel_id: channel.payment_channel_id
    };

    // 调用 API 更新费率
    proxy.$api.merchants.setChannelRate(merchantData.value.id, data).then((r) => {
        if (r.status === 200) {
            ElMessage({
                message: '修改通道费率成功',
                type: 'success',
            })
        }
    }).catch((error) => {
        console.error("Error updating channel rate:", error);
        ElMessage({
            message: '修改通道费率失败',
            type: 'error',
        })
    });
};

// 关闭服务费配置弹窗
const closeConfigDialog = () => {
    isShowConfig.value = false
}

// 确认更新服务费配置
const confirmUpdate = () => {
    ElMessageBox.confirm(
        `确认修改收费配置？`,
        '确认修改',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    ).then(() => {
        proxy.$api.merchants.editServiceCharge(merchantData.value.id, serviceChargeConfig.value).then((r) => {
            if (r.status === 200) {
                ElMessage({
                    message: '修改收费配置成功',
                    type: 'success',
                })
                isShowConfig.value = false
                // 重新查询服务费配置
                queryServiceCharge(merchantData.value.id)
            }
        }).catch((error) => {
            console.error("Error updating :", error);
            ElMessage({
                message: '修改收费配置失败',
                type: 'error',
            })
        });
    }).catch(() => {
        // 取消
        console.log("取消修改");

    });
}

// 弹窗初始化
const isShowChargeList = ref(false)
const open = (item) => {
    isShowChargeList.value = true
    merchantData.value = item
    queryServiceCharge(merchantData.value.id) // 查询服务费配置
}

defineExpose({ open })
</script>

<template>
    <el-dialog v-model="isShowChargeList" width="70%" :destroy-on-close="true">
        <template #header>
            服务费计费-{{ merchantData.merchant_alias }}
        </template>
        <div>
            <c-table ref="table" :tableDataOrigin="tableDataOrigin" :isShowPagination="false" height="200">
                <el-table-column prop="api_billing_type" label="计费方式" min-width="120" align="center">
                    <template #default="scope">
                        <el-tag type="success" v-if="scope.row.api_billing_type != 0" style="cursor:pointer">{{
                            scope.row.api_billing_type == 1 ? '按月付费' :
                                '按流量计费' }}</el-tag>
                        <el-tag type="info" v-else style="cursor:pointer">不计费</el-tag>
                    </template>
                </el-table-column>

                <template v-if="tableDataOrigin?.api_billing_type == 1">
                    <el-table-column prop="api_expire_time" label="有效期截止时间" min-width="130"
                        align="center"></el-table-column>
                    <el-table-column prop="api_monthly_fee" label="应收金额" min-width="120"
                        align="center"></el-table-column>
                </template>

                <template v-if="tableDataOrigin?.api_billing_type == 2">
                    <el-table-column prop="api_balance" label="账户余额" min-width="120" align="center"></el-table-column>
                    <el-table-column prop="api_min_recharge_amount" label="最低充值金额" min-width="120"
                        align="center"></el-table-column>
                    <el-table-column prop="api_fee_rate" label="默认扣费费率百分比" min-width="160"
                        align="center"></el-table-column>
                </template>

                <template v-if="tableDataOrigin?.api_billing_type == 0">
                    <el-table-column prop="api_expire_time" label="有效期截止时间" min-width="180"
                        align="center"></el-table-column>
                    <el-table-column prop="api_monthly_fee" label="应收金额" min-width="120"
                        align="center"></el-table-column>
                    <el-table-column prop="api_balance" label="账户余额" min-width="120" align="center"></el-table-column>
                    <el-table-column prop="api_min_recharge_amount" label="最低充值金额" min-width="120"
                        align="center"></el-table-column>
                    <el-table-column prop="api_fee_rate" label="默认扣费费率百分比" min-width="160"
                        align="center"></el-table-column>
                </template>

                <el-table-column label="操作" min-width="80" align="center" fixed="right">
                    <template #default="scope">
                        <el-link type="primary" @click="openConfig(scope.row)">配置</el-link>
                    </template>
                </el-table-column>
            </c-table>
        </div>

        <el-dialog v-model="isShowConfig" :destroy-on-close="true" :draggable="true" @close="closeForm">
            <template #header>
                服务费配置
            </template>
            <div>
                <el-form :model="serviceChargeConfig" label-width="200px"
                    style="display: flex; flex-direction: column; justify-content: center; overflow: hidden;">
                    <el-form-item label="计费方式：">
                        <el-select v-model="serviceChargeConfig.api_billing_type" @change="handleBillingTypeChange"
                            placeholder="请选择计费方式" style="width: 60%;">
                            <el-option label="不计费" :value="0" />
                            <el-option label="按月付费" :value="1" />
                            <el-option label="按流量付费" :value="2" />
                        </el-select>
                    </el-form-item>
                    <div v-if="serviceChargeConfig.api_billing_type == 1">
                        <el-form-item label="有效期截至时间：">
                            <el-date-picker v-model="serviceChargeConfig.api_expire_time" type="datetime"
                                format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss" placeholder="请选择有效期截至时间"
                                style="width: 60%;" />
                        </el-form-item>
                        <el-form-item label="应收金额：">
                            <el-input v-model="serviceChargeConfig.api_monthly_fee" placeholder="请输入按月付费应收金额"
                                style="width: 60%;" />
                        </el-form-item>
                    </div>
                    <div v-if="serviceChargeConfig.api_billing_type == 2">
                        <el-form-item label="账户余额：">
                            <el-input v-model="serviceChargeConfig.api_balance" placeholder="请输入账户余额"
                                style="width: 60%;" />
                        </el-form-item>
                        <el-form-item label="最低充值金额：">
                            <el-input v-model="serviceChargeConfig.api_min_recharge_amount" placeholder="请输入最低充值金额"
                                style="width: 60%;" />
                        </el-form-item>
                        <el-form-item label="默认扣费费率百分比：">
                            <el-input v-model="serviceChargeConfig.api_fee_rate" placeholder="请输入默认扣费费率百分比"
                                style="width: 60%;" />
                        </el-form-item>

                        <div>
                            <el-table v-if="serviceChargeConfig.api_billing_type == 2" :data="channelRate"
                                v-loading="isLoading">
                                <el-table-column label="通道名称" min-width="120" align="center">
                                    <template #default="scope">
                                        <span>{{ scope.row.payment_channel.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="通道费率" min-width="120" align="center">
                                    <template #default="scope">
                                        <el-input v-model="scope.row.api_fee_rate" class="center-input"
                                            style="width: 50%;"
                                            @blur="handleFeeRateBlur(scope.row.api_fee_rate, scope.row)"
                                            placeholder="请输入通道费率" />
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>

                    <div class="dialog-btn" style="text-align: right;margin-top: 20px;">
                        <el-button @click="closeConfigDialog">取消</el-button>
                        <el-button type="primary" @click="confirmUpdate">确认</el-button>
                    </div>
                </el-form>
            </div>
        </el-dialog>
    </el-dialog>
</template>

<style lang="less" scoped>
::v-deep(.center-input .el-input__inner) {
    text-align: center;
}
</style>
